import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { AppStoreState } from '../interfaces/app-store-state.interface';
import { patientSelector } from '../store/patient/patient-selector';

@Injectable()
export class ReplaceKeyInterceptor implements HttpInterceptor {
  private patientId: number;

  constructor(private store: Store<AppStoreState>) {
    this.observePatientId();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let url = req.url;

    req = req.clone({
      url: url.replace('{patientId}', this.patientId?.toString())
    });

    return next.handle(req);
  }

  private observePatientId() {
    return this.store
      .select(patientSelector)
      .pipe(tap(patient => (this.patientId = patient?.id)))
      .subscribe();
  }
}
