import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import * as TechnicalTranslationActions from './technical-translation-actions';

export const initialState: boolean = false;

const userReducer = createReducer(initialState, on(TechnicalTranslationActions.update, setTechnicalTranslation));

function setTechnicalTranslation(_, { technicalTranslation }: { technicalTranslation: boolean }): boolean {
  return cloneDeep(technicalTranslation);
}

export function reducer(state: boolean, action: Action): boolean {
  return userReducer(state, action);
}
