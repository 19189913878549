import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subscription, tap } from 'rxjs';
import { LocalesApiService } from './core/api-services/locales.api-service';
import { setAppInjector } from './core/misc/app-injector';
import { AuthService } from './core/services/auth.service';
import { feedbackIntegration } from '@sentry/angular';

@UntilDestroy({ checkProperties: true })
@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  browserLang: string;

  private readonly subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private injector: Injector,
    private translateService: TranslateService,
    private localesApiService: LocalesApiService,
    private splashScreenService: SplashScreenService
  ) {
    setAppInjector(this.injector);
  }

  ngOnInit() {
    const needTranslation = true; //todo localStorage

    if (this.authService.getAccessToken()) {
      this.authService.getUserByToken().subscribe();
    }

    if (needTranslation) {
      this.translateService.addLangs(['en']);
      this.translateService.setDefaultLang('en');
    }

    this.localesApiService
      .getLocales()
      .pipe(
        tap(locales => {
          if (needTranslation) {
            this.translateService.addLangs(locales.map(locale => locale.key));
            this.translateService.use(this.translateService.getBrowserLang());
            moment.locale(this.translateService.currentLang);
          }
        }),
        tap(() => this.splashScreenService.hide()),
        tap(() => this.listenToLangChange())
      )
      .subscribe();

    const feedback = feedbackIntegration({
      autoInject: true,
      showBranding: false,
      triggerLabel: 'Feedback',
      enableScreenshot: true,
      formTitle: 'Feedback',
      submitButtonLabel: 'Send feedback'
    });

    feedback.createWidget();
  }

  private listenToLangChange(): void {
    this.subscription.add(
      this.translateService.onLangChange.subscribe(() => {
        moment.locale(this.translateService.currentLang);
      })
    );
  }
}
