import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { Patient } from '../../models/patient.model';
import { PatientActions } from './patient-actions';

export const initialState: Patient = null;

const patientReducer = createReducer(
  initialState,
  on(PatientActions.select, setPatient),
  on(PatientActions.update, update),
  on(PatientActions.updateWithoutConditions, updatePatientWithoutConditions),
  on(PatientActions.deletePatient, () => null)
);

function setPatient(_, { patient }: { patient: Patient }): Patient {
  return cloneDeep(patient);
}

function update(oldPatient: Patient, { patient }: { patient: Patient }): Patient {
  return cloneDeep(patient);
}

function updatePatientWithoutConditions(oldPatient: Patient, { patient }: { patient: Patient }): Patient {
  const _oldPatient = cloneDeep(oldPatient);
  const _patient = cloneDeep(patient);

  _patient.conditions = _oldPatient.conditions;
  return cloneDeep(_patient);
}

export function reducer(state: Patient, action: Action): Patient {
  return patientReducer(state, action);
}
