export interface OnboardingRequestJson {
  id: number;
  type: string;
  status: string;
  comment: string;
}

export class OnboardingRequest {
  constructor(public id: number, public type: string, public status: string, public comment: string) {}

  static fromJson(json: OnboardingRequestJson): OnboardingRequest {
    return new OnboardingRequest(json.id, json.type, json.status, json.comment);
  }
}
