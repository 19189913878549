import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiAlertError } from '../decorators/api-alert-error.decorator';
import { SurveyEntry, SurveyEntryJson } from '../models/survey-entry.model';

const API_ENTRIES_URL = `${environment.apiUrl}/entries`;

@Injectable({
  providedIn: 'root'
})
export class SurveyEntryApiService {
  constructor(private http: HttpClient) {}

  @ApiAlertError()
  getEntry(entryId: number): Observable<SurveyEntry> {
    return this.http
      .get<SurveyEntryJson>(`${API_ENTRIES_URL}/${entryId}`)
      .pipe(map((entry: SurveyEntryJson) => SurveyEntry.fromJson(entry)));
  }
}
