import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import { Survey } from '../../models/survey.model';
import { SurveyActions } from './survey-actions';
import { SurveyEntry } from '../../models/survey-entry.model';

export const initialState: Survey = null;

const surveyReducer = createReducer(
  initialState,
  on(SurveyActions.select, setSurvey),
  on(SurveyActions.update, setSurvey),
  on(SurveyActions.addSurveyEntry, setSurveyEntry),
  on(SurveyActions.deleteSurvey, () => null)
);

function setSurvey(_, { survey }: { survey: Survey }): Survey {
  return cloneDeep(survey);
}

function setSurveyEntry(survey: Survey, { entry }: { entry: SurveyEntry }): Survey {
  const entries = survey.entries ? [...survey.entries] : [];
  entries.push(entry);

  const updatedSurvey = { ...survey, entries };

  return cloneDeep(updatedSurvey);
}

export function reducer(state: Survey, action: Action): Survey {
  return surveyReducer(state, action);
}
