import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { MomentDateModule, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MomentModule } from 'ngx-moment';
import { SplashScreenModule } from './_metronic/partials';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { LanguageInterceptor } from './core/interceptors/language.interceptor';
import { ReplaceKeyInterceptor } from './core/interceptors/replace-key.interceptor';
import { CustomTranslationLoader } from './core/loaders/custom-translation-loader';
import { PaginatorI18n } from './core/misc/paginator-i18n';
import { AppRouteReuseStrategy } from './core/misc/route-reuse-strategy';
import * as patientReducer from './core/store/patient/patient-reducer';
import { SurveyEntryEffects } from './core/store/survey-entry/survey-entry-effects';
import * as surveyEntryReducer from './core/store/survey-entry/survey-entry-reducer';
import * as surveyReducer from './core/store/survey/survey-reducer';
import * as technicalTranslationReducer from './core/store/technical-translation/technical-translation-reducer';
import * as userReducer from './core/store/user/user-reducer';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: CustomTranslationLoader, deps: [HttpClient, Store] }
    }),
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    MatSnackBarModule,
    MomentModule,
    MomentDateModule,
    SplashScreenModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature('user', userReducer.reducer),
    StoreModule.forFeature('patient', patientReducer.reducer),
    StoreModule.forFeature('survey', surveyReducer.reducer),
    StoreModule.forFeature('surveyEntry', surveyEntryReducer.reducer),
    StoreModule.forFeature('technicalTranslation', technicalTranslationReducer.reducer),
    EffectsModule.forRoot(SurveyEntryEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: !isDevMode(), // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: false, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75, // maximum stack trace frames to be stored (in case trace option was provided as true)
      connectInZone: true // If set to true, the connection is established within the Angular zone
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ReplaceKeyInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
      deps: [TranslateService]
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useClass: PaginatorI18n
    },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    provideMomentDateAdapter(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    }
  ]
})
export class AppModule {}
