import { createAction, props } from '@ngrx/store';
import { User } from '../../models/user.model';

export const SELECT = '[User] Select';
export const UPDATE = '[User] Update';
export const PATCH = '[User] Patch';
export const DELETE = '[User] Delete';

export const select = createAction(SELECT, props<{ user: User }>());
export const update = createAction(UPDATE, props<{ user: User }>());
export const patch = createAction(PATCH, props<{ userPartial: Partial<User> }>());
export const deleteUser = createAction(DELETE);
