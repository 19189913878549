export interface EmailConsentsJson {
  newsletters: boolean;
  cohorts: boolean;
  surveys: boolean;
}

export class EmailConsents {
  constructor(public newsletters: boolean, public cohorts: boolean, public surveys: boolean) {}

  static fromJson(json: EmailConsentsJson): EmailConsents {
    return new EmailConsents(json.newsletters, json.cohorts, json.surveys);
  }

  static toJson(emailConsents: EmailConsents): Partial<EmailConsentsJson> {
    return {
      newsletters: emailConsents.newsletters,
      cohorts: emailConsents.cohorts,
      surveys: emailConsents.surveys
    };
  }
}
