import { HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { getAppInjector } from '../misc/app-injector';
import { ErrorService } from '../services/error.service';

export function ApiAlertError(statusBlackList = [403, 404]): MethodDecorator {
  return (_target: () => void, _propertyKey: string, descriptor: PropertyDescriptor) => {
    const original = descriptor.value;

    descriptor.value = function (...args: any[]) {
      const injector = getAppInjector();
      const errorService = injector.get(ErrorService);

      return original.apply(this, args).pipe(
        catchError(error => {
          if (
            error instanceof HttpErrorResponse &&
            error.error &&
            error.error.message &&
            !statusBlackList.includes(error.status)
          ) {
            errorService.logError(error.error.message);
          }

          return throwError(error);
        })
      );
    };
    return descriptor;
  };
}
