import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { SurveyEntryApiService } from 'src/app/core/api-services/survey-entry.api-service';
import { SurveyEntry } from '../../models/survey-entry.model';
import { getById, select } from './survey-entry-actions';

@Injectable()
export class SurveyEntryEffects {
  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getById),
      switchMap((data: { surveyEntryId: number }) =>
        this.surveyEntryApiService
          .getEntry(data.surveyEntryId)
          .pipe(map((surveyEntry: SurveyEntry) => select({ surveyEntry })))
      )
    )
  );

  constructor(private actions$: Actions, private surveyEntryApiService: SurveyEntryApiService) {}
}
