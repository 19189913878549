import { Action, createReducer, on } from '@ngrx/store';
import cloneDeep from 'lodash.clonedeep';

import * as UserActions from './user-actions';
import { User } from '../../models/user.model';

export const initialState: User = null;

const userReducer = createReducer(
  initialState,
  on(UserActions.select, setUser),
  on(UserActions.update, setUser),
  // a shallow copy is made because we are not copying nested objects
  on(UserActions.patch, (state, { userPartial }) => ({
    ...state,
    ...userPartial
  })),
  on(UserActions.deleteUser, () => null)
);

function setUser(_, { user }: { user: User }): User {
  return cloneDeep(user);
}

export function reducer(state: User, action: Action): User {
  return userReducer(state, action);
}
