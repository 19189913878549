import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.headers.get('skip')) {
      return next.handle(req);
    }

    if (this.translateService.currentLang) {
      req = req.clone({
        setHeaders: {
          Language: this.translateService.currentLang
        }
      });
    }

    return next.handle(req);
  }
}
