export interface QuestionOptionSettingsJson {
  column: boolean;
  row: boolean;
}

export class QuestionOptionSettings {
  constructor(public column: boolean, public row: boolean) {}

  static fromJson(json: QuestionOptionSettingsJson): QuestionOptionSettings {
    return new QuestionOptionSettings(json.column, json.row);
  }
}
