import { TranslateLoader } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { userSelector } from '../store/user/user-selector';
import { technicalTranslationSelector } from '../store/technical-translation/technical-translation-selector';

export class CustomTranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient, private store: Store) {}

  getTranslation(lang: string): Observable<any> {
    const headers = new HttpHeaders({
      Language: lang,
      skip: 'true'
    });

    return this.store.select(userSelector).pipe(
      debounceTime(500),
      distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
      switchMap(() => this.store.select(technicalTranslationSelector)),
      distinctUntilChanged(),
      take(1),
      switchMap((technicalTranslation: boolean) =>
        this.http.get(`${environment.apiUrl}/language-lines${technicalTranslation ? '?technical=true' : ''}`, {
          headers
        })
      )
    );
  }
}
