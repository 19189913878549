import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiAlertError } from '../decorators/api-alert-error.decorator';
import { Locale } from '../models/locale.model';

const API_LOCALES_URL = `${environment.apiUrl}/locales`;

@Injectable({
  providedIn: 'root'
})
export class LocalesApiService {
  private locales: Locale[];

  constructor(private http: HttpClient) {}

  @ApiAlertError()
  getLocales(): Observable<Locale[]> {
    if (this.locales) {
      return of(this.locales);
    }

    return this.http.get<any[]>(`${API_LOCALES_URL}`).pipe(
      map(data => Object.entries(data)),
      map(data => data.map(([key, value]) => new Locale(key, value))),
      tap((locales: Locale[]) => (this.locales = locales))
    );
  }
}
