import { createAction, props } from '@ngrx/store';
import { Patient } from '../../models/patient.model';

export const SELECT = '[Patient] Select';
export const UPDATE = '[Patient] Update';
export const UPDATE_WITHOUT_CONDITIONS = '[Patient] Update without conditions';
export const DELETE = '[Patient] Delete';

export const select = createAction(SELECT, props<{ patient: Patient }>());
export const update = createAction(UPDATE, props<{ patient: Patient }>());
export const updateWithoutConditions = createAction(UPDATE_WITHOUT_CONDITIONS, props<{ patient: Patient }>());
export const deletePatient = createAction(DELETE);

export const PatientActions = {
  select,
  update,
  updateWithoutConditions,
  deletePatient
};
