import { QuestionOptionSettings, QuestionOptionSettingsJson } from './question-option-settings.model';

export interface QuestionOptionJson {
  id: number;
  name: string;
  settings: QuestionOptionSettingsJson;
}

export class QuestionOption {
  constructor(public id: number, public name: string, public settings: QuestionOptionSettings) {}

  static fromJson(json: QuestionOptionJson): QuestionOption {
    return new QuestionOption(
      json.id,
      json.name,
      json.settings ? QuestionOptionSettings.fromJson(json.settings) : null
    );
  }
}
