import { QuestionTypeEnum } from '../enums/question-type.enum';
import { QuestionOption, QuestionOptionJson } from './question-option.model';

export interface SectionQuestionJson {
  id: number;
  title: string;
  subtitle: string;
  description: string;
  type: QuestionTypeEnum;
  options: QuestionOptionJson[];
}

export class SectionQuestion {
  constructor(
    public id: number,
    public title: string,
    public subtitle: string,
    public description: string,
    public type: QuestionTypeEnum,
    public options: QuestionOption[]
  ) {}

  static fromJson(json: SectionQuestionJson): SectionQuestion {
    return new SectionQuestion(
      json.id,
      json.title,
      json.subtitle,
      json.description,
      json.type,
      json.options ? json.options.map((option: QuestionOptionJson) => QuestionOption.fromJson(option)) : null
    );
  }
}
