import cloneDeep from 'lodash.clonedeep';
import { AppStoreState } from '../../interfaces/app-store-state.interface';
import { User } from '../../models/user.model';
import { UnitsEnum } from '../../enums/units.enum';
import { RolesEnum } from '../../enums/roles.enum';

export const userSelector = (state: AppStoreState): User => (state && state.user ? cloneDeep(state.user) : null);

export const userUnitsSelector = (state: AppStoreState): UnitsEnum =>
  state && state.user ? cloneDeep(state.user.units) : null;

export const userIsAdminSelector = (state: AppStoreState): boolean =>
  state && state.user && state.user.roles ? state.user.roles.includes(RolesEnum.admin) : false;

export const userIsProfessionalSelector = (state: AppStoreState): boolean =>
  state && state.user && state.user.roles ? state.user.roles.includes(RolesEnum.professional) : false;
