import { RolesEnum } from '../enums/roles.enum';
import { UnitsEnum } from '../enums/units.enum';
import { removeNullUndefined } from '../helpers/remove-null-undefined';
import { EmailConsents, EmailConsentsJson } from './email-consents.model';

export interface UserJson {
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  locale: string;
  units: UnitsEnum;
  email_consents: EmailConsentsJson;
  roles: RolesEnum[];
}

export class User {
  constructor(
    public id: number,
    public name: string,
    public email: string,
    public emailVerifiedAt: string,
    public locale: string,
    public units: UnitsEnum,
    public emailConsents?: EmailConsents,
    public roles?: RolesEnum[]
  ) {}

  static fromJson(json: UserJson): User {
    return new User(
      json.id,
      json.name,
      json.email,
      json.email_verified_at,
      json.locale,
      json.units,
      json.email_consents ? EmailConsents.fromJson(json.email_consents) : null,
      json.roles ? json.roles : []
    );
  }

  static toJson(user: User): Partial<UserJson> {
    return removeNullUndefined({
      id: user.id,
      name: user.name,
      email: user.email,
      locale: user.locale,
      units: user.units,
      email_consents: user.emailConsents ? EmailConsents.toJson(user.emailConsents) : undefined
    });
  }
}
