import { createAction, props } from '@ngrx/store';
import { Survey } from '../../models/survey.model';
import { SurveyEntry } from '../../models/survey-entry.model';

export const SELECT = '[Survey] Select';
export const UPDATE_WITHOUT_CONDITIONS = '[Survey] Update without conditions';
export const ADD_SURVEY_ENTRY_ID = '[Survey] Add Survey Entry Id';
export const DELETE = '[Survey] Delete';

export const select = createAction(SELECT, props<{ survey: Survey }>());
export const update = createAction(UPDATE_WITHOUT_CONDITIONS, props<{ survey: Survey }>());
export const addSurveyEntry = createAction(ADD_SURVEY_ENTRY_ID, props<{ entry: SurveyEntry }>());
export const deleteSurvey = createAction(DELETE);

export const SurveyActions = {
  select,
  update,
  addSurveyEntry,
  deleteSurvey
};
