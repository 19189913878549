import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { finalize, switchMap, tap } from 'rxjs';

@Injectable()
export class PaginatorI18n extends MatPaginatorIntl {
  constructor(private readonly translateService: TranslateService) {
    super();

    this.translateService.onLangChange.subscribe((_event: Event) => {
      this.translateLabels();
    });

    this.translateLabels();
  }

  translateLabels(): void {
    this.translateService
      .get('paginator.firstPage')
      .pipe(
        tap((firstPage: string) => (this.firstPageLabel = firstPage)),
        switchMap(() => this.translateService.get('paginator.lastPage')),
        tap((lastPage: string) => (this.lastPageLabel = lastPage)),
        switchMap(() => this.translateService.get('paginator.nextPage')),
        tap((nextPage: string) => (this.nextPageLabel = nextPage)),
        switchMap(() => this.translateService.get('paginator.previousPage')),
        tap((previousPage: string) => (this.previousPageLabel = previousPage)),
        finalize(() => this.changes.next())
      )
      .subscribe();
  }

  getRangeLabel = (pageNumber: number, pageSize: number, length: number): string => {
    const of = this.translateService.instant('paginator.of');
    const page = this.translateService.instant('paginator.page');

    if (length === 0) {
      return `${page} 1 ${of} 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `${page} ${pageNumber + 1} ${of} ${amountPages}`;
  };
}
