import moment, { Moment } from 'moment';
import { SurveyEntryItem, SurveyEntryItemJson } from './survey-entry-item.model';

export interface SurveyEntryJson {
  id: number;
  completion: number;
  is_editable: boolean;
  created_at: string;
  updated_at: string;
  answers: SurveyEntryItemJson[];
}

export class SurveyEntry {
  constructor(
    public id: number,
    public completion: number,
    public isEditable: boolean,
    public createdAt: Moment,
    public updatedAt: Moment,
    public answers: SurveyEntryItem[]
  ) {}

  static fromJson(json: SurveyEntryJson): SurveyEntry {
    return new SurveyEntry(
      json.id,
      json.completion,
      json.is_editable,
      json.created_at ? moment(json.created_at) : null,
      json.updated_at ? moment(json.updated_at) : null,
      json.answers ? json.answers.map((answer: SurveyEntryItemJson) => SurveyEntryItem.fromJson(answer)) : []
    );
  }
}
