import { Injector } from '@angular/core';

let appInjectorRef: Injector;

export const setAppInjector = (injector: Injector) => {
  if (appInjectorRef) {
    // Injector was already set
    console.error('Injector already set');
    return;
  }
  appInjectorRef = injector;
};

export const getAppInjector = () => appInjectorRef;
