import { createAction, props } from '@ngrx/store';
import { SurveyEntry } from '../../models/survey-entry.model';

export const SELECT = '[SurveyEntry] Select';
export const GET_BY_ID = '[SurveyEntry] Get by id';
export const UPDATE = '[SurveyEntry] Update';
export const DELETE = '[SurveyEntry] Delete';

export const select = createAction(SELECT, props<{ surveyEntry: SurveyEntry }>());
export const getById = createAction(GET_BY_ID, props<{ surveyEntryId: number }>());
export const update = createAction(UPDATE, props<{ surveyEntry: SurveyEntry }>());
export const deleteSurveyEntry = createAction(DELETE);

export const SurveyEntryActions = {
  select,
  getById,
  update,
  deleteSurveyEntry
};
