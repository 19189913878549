export interface AuthModelJson {
  access_token: string;
  refresh_token: string;
}

export class AuthModel {
  constructor(public accessToken: string, public refreshToken: string) {}

  static fromJson(json: AuthModelJson): AuthModel {
    return new AuthModel(json.access_token, json.refresh_token);
  }
}
